import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';

import { AppComponent } from './app.component';
import { ProductsComponent } from './products/products.component';
import { BlogsComponent } from './blogs/blogs.component';
import { WelcomeComponent } from './home/welcome.component';
import { ProductService } from './services/product.service';
import { ApiService } from './services/api.service';
import { HttpClientModule } from '@angular/common/http';
import { ProductDetailComponent } from './products/product-detail.component';

@NgModule({
  declarations: [
    AppComponent,
    ProductsComponent,
    BlogsComponent,
    WelcomeComponent,
    ProductDetailComponent
  ],
  imports: [
    BrowserModule,
    HttpClientModule,
    RouterModule,
    RouterModule.forRoot(
      [
        { path: 'Product', component: ProductsComponent },
        { path: 'Product/:id', component: ProductDetailComponent },
        { path: 'Blog', component: BlogsComponent },
        { path: '', redirectTo: 'Welcome', pathMatch: 'full' },
        { path: '**', component: WelcomeComponent }
      ])
  ],
providers: [ProductService],
bootstrap: [AppComponent]
})
export class AppModule { }
