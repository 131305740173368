import { Component} from '@angular/core';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  title = 'Welcome to View Crossing!';
  private dt = new Date();
  footertext = 'Copyright ' + (new Date()).getFullYear() + ' - View Crossing';
}
